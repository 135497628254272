<template>
  <div class="w-100 p-2 mt-2">
    <page-header :name="'Platform wallet'">
      <b-icon-sliders class="mr-2"></b-icon-sliders>
      {{ $t('page.title.configuration_management') }}
      <template v-slot:right>
<!--        <b-button @click="addNew()" size="sm" variant="success">{{ $t('btn.add') }}</b-button>-->
      </template>
    </page-header>
<!--    <page-card>-->
<!--      <template v-slot:header>-->
<!--        {{ $t('page.filters') }}-->
<!--      </template>-->
<!--      <template v-slot:content>-->
<!--&lt;!&ndash;        <div class="filters sygni-grid security-group">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="col-6">&ndash;&gt;-->
<!--&lt;!&ndash;              <filter-input&ndash;&gt;-->
<!--&lt;!&ndash;                  :filter-query="filterQuery"&ndash;&gt;-->
<!--&lt;!&ndash;                  filter-operator="like"&ndash;&gt;-->
<!--&lt;!&ndash;                  filter-name="key"&ndash;&gt;-->
<!--&lt;!&ndash;                  :label="$t('filters.label.id')">&ndash;&gt;-->
<!--&lt;!&ndash;              </filter-input>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="col-6">&ndash;&gt;-->
<!--&lt;!&ndash;              <filter-input&ndash;&gt;-->
<!--&lt;!&ndash;                  :filter-query="filterQuery"&ndash;&gt;-->
<!--&lt;!&ndash;                  filter-operator="like"&ndash;&gt;-->
<!--&lt;!&ndash;                  filter-name="value"&ndash;&gt;-->
<!--&lt;!&ndash;                  :label="$t('filters.label.value_phrase')">&ndash;&gt;-->
<!--&lt;!&ndash;              </filter-input>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </template>-->
<!--      <template v-slot:footer>-->
<!--        <b-button @click="resetFilters()" variant="secondary">{{ $t('filters.btn.clear') }}</b-button>-->
<!--        <b-button @click="applyFilters()" variant="primary">{{ $t('filters.btn.apply') }}</b-button>-->
<!--      </template>-->
<!--    </page-card>-->
    <page-card>
      <template v-slot:header>
        {{ $t('page.list') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <grid-pagination :total-count="totalCount"
                           :per-page="perPage"
                           :current-page="currentPage"
                           @load-items="paginationReload"></grid-pagination>

          <div class="table-responsive">
            <b-table striped show-empty
                     :empty-text="$t('table.no_results')"
                     :busy="isBusy"
                     hover
                     head-variant="dark"
                     outlined
                     :sort-by.sync="orderColumn"
                     :sort-desc.sync="orderDirection"
                     :no-local-sorting="true"
                     :items="items"
                     :fields="fields">
              <template #cell(created_at)="data">
                <div v-if="data.item.created_at" class="ml-2">
                  {{ data.item.created_at | dateFormat('YYYY-MM-DD HH:mm') }}
                </div>
              </template>
              <template #cell(updated_at)="data">
                <div v-if="data.item.updated_at" class="ml-2">
                  {{ data.item.updated_at | dateFormat('YYYY-MM-DD HH:mm') }}
                </div>
              </template>
              <template #cell(actions)="data">
                <div class="table-action--cell">
                  <b-button
                      variant="success" size="sm"
                      @click="editItem(data.item)"
                  >
                    {{ $t('table.btn.edit') }}
                  </b-button>
<!--                  <b-button-->
<!--                      variant="danger" size="sm"-->
<!--                      @click="deleteItem(data.item)">-->
<!--                    {{ $t('table.btn.delete') }}-->
<!--                  </b-button>-->
                </div>
              </template>

              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>{{ $t('state.loading') }}</strong>
                </div>
              </template>
            </b-table>
          </div>

          <grid-pagination :total-count="totalCount"
                           :per-page="perPage"
                           :current-page="currentPage"
                           @load-items="paginationReload"></grid-pagination>
        </div>
      </template>
    </page-card>
  </div>
</template>

<script>
// import FilterInput from "../../components/grid/filters/FilterInput";
import GridMixin from '../../components/grid/GridMixin';
import CreateConfigurationModal from "./CreateConfigurationModal";
import axios from "axios";
import alertify from 'alertifyjs';
import GridPagination from "../../components/grid/grid-pagination/GridPagination";
import PageHeader from "../../components/page/PageHeader";
import PageCard from "../../components/page/PageCard";
import Vue from "vue";

export default {
  mixins: [GridMixin],
  components: {
    PageCard,
    PageHeader,
    // FilterInput,
    GridPagination,
  },
  data() {
    return {
      gridGetUrl: '/app-config/list',
      orderColumn: 'id',
      orderDirection: false,
      fields: [
        {key: 'key', label: this.$t('table.header.key')},
        {key: 'value', label: this.$t('table.header.value')},
        {key: 'actions', label: this.$t('table.header.actions')}
      ],
      items: [],
      createModalInstance: null,
    };
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    editItem(item) {
      const
          ModalConstructor = Vue.extend(CreateConfigurationModal),
          modal = new ModalConstructor({parent: this});
      modal.item = item;

      modal.$mount();
    },
    loadItems() {
      axios.defaults.headers.common['X-AUTH-TOKEN'] = this.$store.getters["root/apiKey"];
      this.isBusy = true;
      let offset = this.perPage * (this.currentPage - 1);
      let fullUrl = this.gridGetUrl + '?limit='
          + this.perPage
          + '&offset=' + offset
          + this.createFilterQuery()
          + this.createSortQuery();

      axios.get(fullUrl)
          .then(response => {
            this.items = response.data.results;
            this.totalCount = response.data.totalCount;
            this.isBusy = false;
          })
          .catch(error => {
            console.log(error);
          });
    },
    deleteItem(item) {
      this.$bvModal.msgBoxConfirm('Configuration: ' + item.key, {
        title: 'Are you sure to delete configuration?',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        okVariant: 'danger'
      })
          .then(value => {
            if (value) {
              axios.post(`/app-config/delete`, {"id": item.key})
                  .then(() => {
                    this.loadItems();
                  })
                  .catch(() => {
                    alertify.error('Error during deleting config item.');
                  });
            }
          });
    },
    addNew() {
      const
          ModalConstructor = Vue.extend(CreateConfigurationModal),
          modal = new ModalConstructor({parent: this});

      modal.$mount();
    },
    openModal() {
      let ModalConstructor;
      if (!this.createModalInstance) {
        ModalConstructor = Vue.extend(CreateConfigurationModal)
        this.createModalInstance = new ModalConstructor({parent: this});
        this.createModalInstance.$mount();
        this.createModalInstance.openModal();
        this.createModalInstance.$on('savedConfiguration', () => {
          this.loadItems();
        });
      } else {
        this.createModalInstance.openModal();
      }
    }
  }
}
</script>
