import Vue from 'vue';
import VueRouter from "vue-router";
import Vuelidate from "vuelidate";
import AdminApp from "./containers/AdminApp";
import storePlugin from "./store/storePlugin";
import ConfigurationManagementModule from "@/modules/configuration-management/ConfigurationManagementModule.vue";
import vueJsonEditor from 'vue-json-editor';

// bootstrap
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import "admin-lte/dist/css/adminlte.css";
import './styles/main.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import './components/grid/styles.scss';

import 'alertifyjs/build/css/alertify.css';
import AdminRootApp from "./containers/AdminRootApp";
import AdminAuth from "./containers/AdminAuth";
import Login from "./modules/auth/containers/Login";
import VueI18n from 'vue-i18n';
import {clientsRouting} from "@/modules/clients/routing";
import {authorsRouting} from "@/modules/authors/routing";
import {scoresRouting} from "@/modules/scores/routing";
import {masterClassRouting} from "@/modules/masterClass/routing";
import {lessonRouting} from "@/modules/lessons/routing";
import {ratesRouting} from "@/modules/rates/routing";
import {rentalRouting} from "@/modules/rental/routing";
import {paymentRouting} from "@/modules/payment/routing";
import {subscriptionOfferRouting} from "@/modules/subscriptionOffer/routing";
import {cmsRouting} from "@/modules/cms/routing";
import {statisticsRouting} from "@/modules/statistics/routing";
import axios from 'axios';
import {defaultLocale, languages} from "./i18n";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(vueJsonEditor);
Vue.use(Vuelidate);
Vue.use(storePlugin);

const messages = Object.assign(languages);

const DashboardModule = () => import(/* webpackChunkName: "DashboardModule" */ './modules/dashboard/DashboardModule');
window.i18n = new VueI18n({
    locale: 'pl',
    messages
});

export const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'pl',
    silentTranslationWarn: true,
    messages
});

Vue.config.productionTip = false

export function setI18nLanguage(lang) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export function loadLanguageAsync(lang, first) {
    // If the same language
    if (i18n.locale === lang && !first) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    return axios.get(`/i18n/${lang}.json`, {baseURL: ''}).then(response => {
        i18n.setLocaleMessage(lang, response.data)
        setI18nLanguage(lang)
    })
}

export function createFilterQuery(query) {
    let filter = '';
    Object.keys(query).forEach(key => {
        filter += query[key];

        if (key === 'phone') {
            filter += '&filters[phone][0][operator]=like'
        }

        if (key === 'phrase') {
            filter += '&filters[phrase][0][operator]=like'
        }

    })
    return filter;
}

export function createSortQuery(orderColumn, orderDirection) {
    let sorting;
    if (orderColumn) {
        if (orderDirection === true) {
            sorting = encodeURIComponent('+' + orderColumn);
        } else {
            sorting = encodeURIComponent('-' + orderColumn);
        }
        return '&sort[]=' + sorting;
    }
    return '';
}

export class AdminVue {
    constructor() {

        this.initWorkflowApp();
    }

    checkGuard(to, from, next) {
        const token = localStorage.getItem('apiKey');
        if (token) {
            next();
        } else {
            next('auth')
        }
    }

    initWorkflowApp() {
        const routes = [
            {
                path: '/auth',
                component: AdminAuth,
                children: [
                    {path: '/', name: 'login', component: Login},
                    {path: '/register', name: 'register', component: DashboardModule},
                ]
            },
            {
                path: '/',
                component: AdminApp,
                beforeEnter: this.checkGuard,
                children: [
                    {path: '/', name: 'dashboard', component: DashboardModule},
                    ...clientsRouting,
                    ...authorsRouting,
                    ...scoresRouting,
                    ...masterClassRouting,
                    ...lessonRouting,
                    ...subscriptionOfferRouting,
                    ...ratesRouting,
                    ...rentalRouting,
                    ...paymentRouting,
                    ...cmsRouting,
                    ...statisticsRouting,
                    {path: '/app-config', name: 'app-config', component: ConfigurationManagementModule}
                ]
            },
        ]

        const router = new VueRouter({
            routes,
            linkExactActiveClass: 'active'
        })

        loadLanguageAsync(defaultLocale, true);

        window.AdminVue = new Vue({
            i18n,
            router,
            render: h => h(AdminRootApp),
        }).$mount('#app')
    }
}

new AdminVue();


