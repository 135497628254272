<template>
  <div class="create-group-modal">
    <b-button @click="openModal" size="sm" variant="success">
      {{ $t('configuration.button.add') }}
    </b-button>
    <b-modal
        ref="create-configuration-modal"
        size="xl"
        :hide-footer="isLoading"
    >
      <div v-if="isLoading" class="d-flex justify-content-center m-5">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">{{ $t('state.loading') }}</span>
        </div>
      </div>
      <template v-if="!isLoading" #modal-header>
        <div class="w-100 d-flex justify-content-between align-items-center">
          <h4>{{ $t('configuration.add_new_configuration.title') }}</h4>
          <div class="d-flex">
            <b-button type="button" class="mr-1" variant="secondary" @click="hideModal">
              {{ $t('configuration.button.cancel') }}
            </b-button>
            <b-button variant="primary" @click="saveConfiguration">
              {{ !item ? $t('configuration.button.add') : $t('configuration.button.edit') }}
            </b-button>
          </div>
        </div>
      </template>
      <b-card v-if="!isLoading">
        <b-card-body>
          <b-form-group
              :label="$t('configuration.key')"
              label-for="key"
          >
            <b-form-input
                v-model="key"
                @input="$v.key.$model = $event"
                :state="!$v.key.$dirty ? null :(!$v.key.$error)"
                v-if="!item"
                trim></b-form-input>
            <p v-if="$v.key.$dirty && !$v.key.required" class="text-danger">{{
                $t('configuration.errors.required')
              }}</p>
          </b-form-group>
          <h6>{{ item.key }}</h6>
          <div id="json-editor" ref="json-editor" class="jsoneditor-container"></div>
        </b-card-body>
      </b-card>
      <template v-if="!isLoading" #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button type="button" class="mr-1" variant="secondary" @click="hideModal">
            {{ $t('configuration.button.cancel') }}
          </b-button>
          <b-button variant="primary" @click="saveConfiguration">
            {{ !item ? $t('configuration.button.add') : $t('configuration.button.edit') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import {required} from 'vuelidate/lib/validators';
import JSONEditor from 'jsoneditor';
import alertify from 'alertifyjs';

export default {
  data() {
    return {
      key: '',
      editor: null,
      item: null,
      json: {},
      jsonError: [],
      isLoading: true,
      errors: {
        required: 'This field is required!'
      }
    }
  },
  validations: {
    key: {
      required
    }
  },
  mounted() {
    this.openModal();
  },
  methods: {
    loadJSONEditor() {
      return new Promise(resolve => {
        let script = document.createElement('script')

        script.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/jsoneditor/9.4.2/jsoneditor.min.js')
        document.head.appendChild(script);

        script.onload = () => {
          resolve();
        }
      })
          .then(() => {
            const container = document.getElementById("json-editor");
            const options = {
              mode: 'code',
              enableTransform: false
            };
            this.editor = new JSONEditor(container, options);
            const initialJson = this.json;

            this.editor.set(initialJson);
          });
    },
    saveConfiguration() {
      this.editor.validate().then(data => {
        if (!data.length) {
          this.validAndSend();
        }
      });
    },
    validAndSend() {
      console.log(this.$v);
      // if (!this.$v.$invalid) {
        console.log('ddddddd');
        const url = this.item ? '/app-config/update' : `/app-config/create`;
        let dataToSend = {};
        dataToSend = {
          id: this.item.key,
          value: JSON.stringify(this.editor.get())
        }

        axios.post(url, dataToSend)
            .then(() => {
              this.hideModal();
              alertify.success('notify.configuration_save.success');
              this.$parent.loadItems();
            })
            .catch(error => {
              alertify.error(error.response.data.label);
            });
      // }
    },
    setEditData(item) {
      this.json = JSON.parse(item.value);
      this.key = item.id;
      this.isLoading = false;
      this.loadJSONEditor();
    },
    openModal() {
      if (this.item) {
        this.setEditData(this.item);
      } else {
        this.isLoading = false;
        this.loadJSONEditor();
      }
      this.$refs['create-configuration-modal'].show();
    },
    hideModal() {
      this.$refs['create-configuration-modal'].hide();
    },
    resetProperties() {
      this.key = '';
      this.json = {};
      this.isLoading = true;
      this.$v.$reset();
    }
  }
}
</script>

<style>

</style>
